import React, { useState } from "react";
import { Loader2, Info } from "lucide-react";
import { useAuth } from "../../../../providers/AuthProvider";
import api from "../../../../api";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import "../settings.scss";
const UserAccount2FA = () => {
    const { authTokens } = useAuth();
	const [selectedTab, setSelectedTab] = useState("my account"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState("");
	const [dataPlaceState, setDataPlaceState] = useState({ 
		                                                    authentication_code : ""
														 }); 
	
	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 

	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	const onInputChange = (field, e) => {
		setDataPlaceState((prevState) => ({
		  ...prevState,
		  [field]: e.target.value,
		}));
	};


	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const process = useMutation({
		mutationFn: (fields) => {
			return api?.save2Fa({ authentication: fields, token: authTokens.IdToken});
		},
		onError: (error, variables, context) => {
            const errors = error.response.data.errors;
		},
		onSuccess: async (data, variables, context) => {

		   //Saving response content
		   const responseData = data.data.data.attributes;
		   if(data.status === 201 || data.status === 200){
			  await reloadPaymentMethods();
			  setSuccessPosted(true);
		   }
		},
	});

	const onSaving = (fields) => {
		fields = {
					...fields,
					authentication_code:dataPlaceState?.authentication_code
				 };	
        process.mutate(fields);
	};

	return (
		<div className="accountsettings_paymentmethods_model">
		  <div className="accountsettings_paymentmethods_model_heading">Two Factor Authentication</div>

          <div className="flex_container flexing_content full_width padding_top_40">
			
			<div className="form_input_field column_30"> 
				<img src="https://s3-alpha-sig.figma.com/img/9b9a/10ae/93e7dcfe1512e29405a9785c049a637a?Expires=1740355200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=h8k-OvAoOc1YSg9iJq8mpH92DlTLUNRY4VgA67P64FBLiYSxfXuIJyCXGCJ~uXipmtNBCl9Jfik1rjCmYX2sqKojt~HsGlLaTrzdJOwStbwmmR3NyVYqE5M1DsRif-ArChGbYJnWCgBBpweYPYuwtZuHQ7NUWfN7L9aXgroJVwwx~q6-x5fHBVasx9qkdNCWHo86F30URZ0Y5Mr1YKuKeSg2TDQ-dBZfkUMaXcXm3LOuzlnHNk5Omluw62mp2IrNkcDbUsRzXHeH02au7dBXsYHhXJAu-ToY3VKU6upOe91kmedGHnbPSC2tlOUdeLASsFl6RR3zMorogtEfxX9h~w__" alt="hc67 dqap kmtu 6y63" />     
			</div>

			<div className="form_input_field column_70"> 
				<label className="column_100  text-left margin_bottom_10"> <b>Step 1</b>Open the authenticator mobile app</label>
				<label className="column_100  text-left margin_bottom_10"> <b>Step 2</b>Scan the QR code or enter <strong>hc67 dqap kmtu 6y63</strong></label>
				<label className="column_100  text-left"> <b>Step 3</b>Enter the generated code below </label>
				
				<div className="form_input_field column_100 nopadding text-left"> 
					<label className="column_100  text-left nopadding-left margin_top_10">Authentication Code <sup>*</sup></label>
					<input className="column_70 input" type="text" 
						placeholder=""
						name="authentication_code"
								{...register("authentication_code", { required: true })}
						value={dataPlaceState.authentication_code}
						onChange={(e) => onInputChange("authentication_code", e)}  />
						{errors?.authentication_code?.type === "required" && (
													<span className="input_error"><Info className="input_error_icon" /> Wallet address is required </span>
													)} 				
					</div>	
				</div>

		  </div>	
		  <div className="add_continue_button column_100"> 
            <button>Continue</button>
		  </div>

		</div>
	);
};
export default UserAccount2FA;
